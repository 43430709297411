import axios from 'axios';
import {Vue} from 'vue-property-decorator';
import Common from '@/services/Common';

/**
 * Va permettre de définir les actions qui vont intéragir avec la BDD via l'API.
 */
export class Crud {
    public static readonly EMAIL_TYPE_REPORT = 1;

    private readonly pathName: string;

    /**
     * @param pathName
     */
    constructor(pathName: string) {
        this.pathName = pathName;
    }

    /**
     * Crée un item
     *
     * @public
     * @param {any} doc
     * @return {Promise<any>}
     */
    public create(doc: any): Promise<any> {
        const data = {
            docData: doc,
        };

        return axios.put(
            this.getUrl('/create', true),
            data,
            this.getHeader(),
        );
    }

    /**
     * Met à jour un item
     *
     * @public
     * @param {any} doc
     * @return {Promise<any>}
     */
    public update(doc: any): Promise<any> {
        const data: any = {
            docData: doc,
        };

        return axios.put(
            this.getUrl('/update/' + doc._id, true),
            data,
            this.getHeader(),
        );
    }

    /**
     * Supprime un item
     *
     * @public
     * @param {string} id
     * @return {Promise<any>}
     */
    public delete(id: string): Promise<any> {
        return axios.delete(
            this.getUrl('/delete/' + id, true),
            this.getHeader(),
        );
    }

    /**
     * Récupére un item spécifique par ID.
     *
     * @public
     * @param {string} id
     * @return {Promise<any>}
     */
    public getItem(id: string): Promise<any> {
        return axios.get(
            this.getUrl('/doc/' + id, true),
        ).then((result: any) => {
            return result.data;
        });
    }

    /**
     * Récupére la liste d'items
     *
     * @public
     * @param {any} selector
     * @param {any[]|null} sort
     * @param {any} field
     * @param {any} getUid
     * @return {Promise<any>}
     */
    public getListByUser(selector: any = {title: {$exists: true}}, sort: any[]|null = null, field: any = null, getUid: any = null): Promise<any> {

        if (getUid === null) {
            getUid = Common.getCurrentUserId();
        }

        const newSelector = {
            $and: [
                {uid: getUid},
                selector,
            ],
        };

        return this.getFullList(newSelector, sort, field);
    }

    /**
     * Récupére la liste d'items de tous les utilisateurs
     *
     * @public
     * @param {any} selector
     * @param {any[]|null} sort
     * @param {any} fields
     * @return {Promise<any>}
     */
    public getFullList(selector: any = {title: {$exists: true}}, sort: any[]|null = null, fields: any = null): Promise<any> {
        const data: any = {};
        data.selector = {
            $and: [
                selector,
            ],
        };

        if (sort !== null) {
            data.sort = sort;
        }

        if (fields !== null) {
            data.fields = fields;
        }

        return axios.post(
            this.getUrl('/find', true),
            data,
            this.getHeader(),
        ).then((tmpList: any) => {
            return tmpList.data.docs;
        });
    }

    /**
     * Récupère les listes en les groupant
     *
     * @public
     * @param {any} selector
     * @param {any[]|null} sort
     * @param {any} fields
     * @return {Promise<any>}
     */
    public getFullListGroupByType(
        selector: any = { label: { $exists: true } },
        sort: any[] | null = [{ label: 'asc' }],
        fields: any = null,
    ): Promise<any> {
        const data: any = {};
        data.selector = {
            $and: [selector],
        };

        if (sort !== null) {
            data.sort = sort;
        }

        if (fields !== null) {
            data.fields = fields;
        }

        return axios
            .post(this.getUrl('/findAllGroupByType', true), data, this.getHeader())
            .then((tmpList: any) => {
                return tmpList.data.docs;
            });
    }

    /**
     * Importer une image
     *
     * @param {any} formData
     */
    public importImage(formData: any) {
        return axios.post(
            this.getUrl('/upload/image/import'),
            formData,
            this.getHeader({
                'Content-Type': 'multipart/form-data; boundary=${form._boundary}',
            }),
        ).then((rep: any) => {
            return rep;
        }).catch(() => {
            alert('Une erreur s\'est produite lors de l\'upload du fichier !');
        });
    }

    /**
     * Supprime une image
     *
     * @param {string} imgPath
     */
    public deleteImage(imgPath: string) {
        axios.post(
            this.getUrl('/upload/image/delete'),
            {path: imgPath},
            this.getHeader(),
        ).then((rep: any) => {
            return rep;
        }).catch(() => {
            alert('Une erreur s\'est produite lors de la suppression du fichier !');
        });
    }

    /**
     * Récupére la liste de tous les utilisateurs
     *
     * @public
     * @return {Promise<any>}
     */
    public getUsersList(): Promise<any> {
        return axios.get(
            this.getUrl('/users/list'),
            this.getHeader(),
        ).then((tmpList: any) => {
            return tmpList.data;
        });
    }

    /**
     * Récupére les news rss
     *
     * @public
     * @return {Promise<any>}
     * @param {string} urlData
     * @param {number} sizeData
     */
    public getRss(urlData: string, sizeData: number): Promise<any> {
        const data: any = {
            url: urlData,
            size: sizeData,
        };

        return axios.post(
            this.getUrl('/rss/data'),
            data,
            this.getHeader(),
        ).then((result: any) => {
            return result.data;
        }).catch((err) => {
            alert('Error : ' + err);
        });
    }

    /**
     * Récupére le planning Manga-news.com
     *
     * @public
     * @param {string} username
     * @return {Promise<any>}
     */
    public getMangaNewsPlanning(username: string): Promise<any> {
        const data = {
            user: username,
        };

        const result: any = {
            data: [],
            error: false,
        };

        return axios.post(
            this.getUrl('/rss/manganews/planning'),
            data,
            this.getHeader(),
        ).then((res: any) => {
            result.data = res.data.items;
            result.error = res.data.error;
            return result;
        }).catch(() => {
            result.error = true;
            return result;
        });
    }

    /**
     * Envoi un email à l'api
     *
     * @public
     * @param {number} type
     * @param {string} msg
     * @param {string} usrname
     */
    public sendEmail(type: number, msg: string, usrname: string) {
        const data: any = {
            message: msg,
            username: usrname,
        };

        let path = '';

        if (type === Crud.EMAIL_TYPE_REPORT) {
            path = '/mailer/report';
        }

        return axios.post(
            this.getUrl(path),
            data,
            this.getHeader(),
        ).then((result: any) => {
            return result.data;
        }).catch((err) => {
            alert('Error email report : ' + err);
        });
    }

    /**
     * Prépare le header pour les requêtes
     *
     * @private
     * @param {any} addHeader
     * @return {any}
     */
    private getHeader(addHeader: any = {}): any {
        if (!Common.checkIsNotUndifined(Vue.prototype.$keycloak.token)) {
            return null;
        }

        return {
            headers: {
                ...addHeader,
                Authorization: 'Bearer ' + Vue.prototype.$keycloak.token,
            },
        };
    }

    /**
     * Prépare l'url
     *
     * @private
     * @param {string} path
     * @param {boolean} forCouchDb permet d'ajouter le statut de la db et le nom de la bdd
     * @return {string}
     */
    private getUrl(path: string, forCouchDb: boolean = false): string {
        if (forCouchDb) {
            path = '/' + process.env.VUE_APP_DB_STATUS + '/' + this.pathName + path;
        }

        return process.env.VUE_APP_API_URL + path;
    }
}
