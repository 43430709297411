import {BaseJeuxModel} from '@/models/Jeux/BaseJeuxModel';
import {config} from '@/config/CategoryConfig';

export class JeuxSocieteItem extends BaseJeuxModel {
    private _auteur: string = '';
    private _extension: boolean = false;
    private _type: string = 'Jeux de plateau';
    private _editeur: string = '';
    private _minPlayer: number = 0;
    private _maxPlayer: number = 0;
    private _tempsPartie: number = 0;
    private _genre: string[] = [];
    private _langue: string = 'Français';

    /**
     * @param {any} data
     * @param {boolean} duplicate
     */
    constructor(data: any = {}, duplicate: boolean = false) {
        super(data, duplicate, config.jeuxSociete.category);
        if (data.auteur) { this._auteur = data.auteur; }
        if (data.extension) { this._extension = data.extension; }
        if (data.type) { this._type = data.type; }
        if (data.editeur) { this._editeur = data.editeur; }
        if (data.minPlayer) { this._minPlayer = data.minPlayer; }
        if (data.maxPlayer) { this._maxPlayer = data.maxPlayer; }
        if (data.tempsPartie) { this._tempsPartie = data.tempsPartie; }
        if (data.genre) { this._genre = data.genre; }
        if (data.langue) { this._langue = data.langue; }
    }

    get auteur(): string {
        return this._auteur;
    }

    set auteur(value: string) {
        this._auteur = value;
    }

    get extension(): boolean {
        return this._extension;
    }

    set extension(value: boolean) {
        this._extension = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get editeur(): string {
        return this._editeur;
    }

    set editeur(value: string) {
        this._editeur = value;
    }

    get minPlayer(): number {
        return this._minPlayer;
    }

    set minPlayer(value: number) {
        this._minPlayer = value;
    }

    get maxPlayer(): number {
        return this._maxPlayer;
    }

    set maxPlayer(value: number) {
        this._maxPlayer = value;
    }

    get tempsPartie(): number {
        return this._tempsPartie;
    }

    set tempsPartie(value: number) {
        this._tempsPartie = value;
    }

    get genre(): string[] {
        return this._genre;
    }

    set genre(value: string[]) {
        this._genre = value.sort();
    }

    get langue(): string {
        return this._langue;
    }

    set langue(value: string) {
        this._langue = value;
    }
}
