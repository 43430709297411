
    import {Component} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    // @ts-ignore
    import NavBar from '@/components/NavBar/NavBar.vue';
    // @ts-ignore
    import LastUpdate from '@/components/Home/LastUpdate/LastUpdate.vue';
    import LastNews from '@/components/Home/LastNews/LastNews.vue';
    import ModalChangeLog from '@/components/ModalChangeLog/ModalChangeLog.vue';
    import Common from '@/services/Common';
    // @ts-ignore
    import {Crud} from '@/services/Crud';
    import {config} from '@/config/CategoryConfig';
    import {BaseScript} from '@/components/BaseScript';
    import {versions} from '@/components/ModalChangeLog/versions';
    import GlobalStatistique from '@/components/Statistiques/GlobalStatistique.vue';
    import {categoryTypes} from '@/config/CategoryType';


    // modules
    const general = namespace('general');

    // @ts-ignore
    @Component({
        name: 'home',
        components: {
            NavBar,
            ModalChangeLog,
            LastUpdate,
            LastNews,
            GlobalStatistique,
        },
    })
    export default class Home extends BaseScript {
        // actions
        @general.Action('setLoadingStatus') private readonly setLoadingStatus!: any;
        @general.Action('checkCollectionUser') private checkCollectionUser!: any;

        private cookie: boolean = false; // cookie permettant de savoir si la barre de news a été vu
        private itemsList: any = { // utilisé pour les statistiques
            size: {},
            graph: {
                allData: {},
                data: [],
                labels: [],
                colors: [],
            },
        };
        private currentVersion: any = versions[process.env.VUE_APP_CURRENT_VERSION][0].subversion[0]; // version actuelle de l'application
        private categoryTypes: any = categoryTypes; // récupération des constantes des noms des types de catégorie
        private collectionMsg: string = ''; // message à affiché à côté du titre "MA COLLECTION".

        constructor() {
            super();

            // récupération du nom du cookie de la dernière version
            const currentNewsCookie = 'maj-' + this.currentVersion.version + '-news';
            // cookie news :
            this.cookie = (Common.getCookie(currentNewsCookie) === 'true');

            // si le cookie n'existe pas, on le crée
            if (!this.cookie) {
                const expiryDate = new Date();
                expiryDate.setMonth(expiryDate.getMonth() + 1);
                document.cookie = currentNewsCookie + '=true; expires=' + expiryDate;
            }
        }

        /**
         * @private
         * @async
         * @return {Promise<void>}
         */
        private async mounted(): Promise<void> {
            this.setLoadingStatus(true);

            // on récupére l'uid utilisateur, notamment pour l'initialisation des données statistiques
            let uid: null | string = null;
            await this.checkCollectionUser().then((getUid: any) => {
                uid = getUid;
            });

            // on initialise les données pour les statistiques
            await this.initStatData(uid);

            // on cache la barre de chargement
            this.setLoadingStatus(false);
        }

        /**
         * On récupére la collection et leurs taille pour les statistiques
         *
         * @private
         * @async
         * @param uid null|string - utilisé pour récupéré la liste des items en fonction de l'uid utilisateur
         * @return {void}
         */
        private async initStatData(uid: null | string): Promise<void> {
            // on récupére la collection complète.
            const crud: Crud = new Crud(process.env.VUE_APP_API_ITEMS_PATH);
            let allData: any = [];
            await crud.getListByUser({title: {$exists: true}}, null, null, uid).then((list: any) => allData = list);

            // on initialise les paramètres permetant à récupérer seulement la taille
            this.itemsList.size.totalLength = 0;

            allData.forEach((item: any) => {
                // On prend en compte que les items non vendu et non emprunté. On vérifie aussi  qu'il n'y ait pas un item "blocnote" qui n'existe plus aujourd'hui
                if (item.etat !== 'Emprunté' && item.etat !== 'Vendu' && item.category !== 'blocnote') {

                    // Si la catégory de l'item n'est pas associé à un type, alors on utilise la catégorie comme clé
                    const itemType: string = config[item.category].type !== '' ? config[item.category].type : item.category;

                    // si la clé n'éxiste pas encore, on l'initialise
                    if (!Common.checkIsNotUndifined(this.itemsList.size[itemType])) {
                        this.itemsList.size[itemType] = {
                            total: 0,
                            category: {},
                        };
                    }

                    // Si la categorie de la partie size n'existe pas, on l'initialise
                    if (!Common.checkIsNotUndifined(this.itemsList.size[itemType].category[item.category])) {
                        this.itemsList.size[itemType].category[item.category] = 0;
                    }

                    // Si la categorie de la partie graph n'existe pas, on l'initialise
                    if (!Common.checkIsNotUndifined(this.itemsList.graph.allData[item.category])) {
                        this.itemsList.graph.allData[item.category] = 0;
                    }

                    // si c'est un livre, on rajoute le nombre de tome
                    if (itemType === this.categoryTypes.LIVRES_TYPE) {
                        this.itemsList.size[itemType].total += item.tomes.nbrTome;
                        this.itemsList.size[itemType].category[item.category] += item.tomes.nbrTome;
                        this.itemsList.size.totalLength += item.tomes.nbrTome;

                        this.itemsList.graph.allData[item.category] += item.tomes.nbrTome;
                    } else {
                        // sinon l'item en lui même
                        this.itemsList.size[itemType].total++;
                        this.itemsList.size[itemType].category[item.category]++;

                        this.itemsList.graph.allData[item.category]++;
                        this.itemsList.size.totalLength++;
                    }
                }

            });

            // on formate les données pour le graph
            this.formatDataGraph('all');
        }

        /**
         * Permet de formater les données, labels et couleurs pour le graphique
         * Envoyer "all" pour toutes les données de la collection.
         *
         * @private
         * @param {string} type - nom de la catégorie dont on veut récupérer les données. Peut-être null, dans ce cas on affiche toutes les données
         * @param {string} msg - correspond au message à afficher
         * @return {void}
         */
        private formatDataGraph(type: string, msg: string = ''): void {
            this.itemsList.graph.data = [];
            this.itemsList.graph.labels = [];
            this.itemsList.graph.colors = [];

            let tmpGraphData: any = null;

            // si on reçoit 'all', on récupére toutes les données de la bdd.
            // sinon on affiche les données du type sélectionné si elles existent.
            if (type === 'all') {
                tmpGraphData = this.itemsList.graph.allData;
            } else if (Common.checkIsNotUndifined(this.itemsList.size[type])) {
                tmpGraphData = this.itemsList.size[type].category;
            }

            // si on a bien des données dans le type sélectionné, on les formates pour les afficher sur le graph
            if (tmpGraphData !== null) {
              const sortGraphData = [];
              // données sous forme de tableau pour les trier
              for (const row in tmpGraphData) {
                if (tmpGraphData.hasOwnProperty(row)) {
                  sortGraphData.push([row, tmpGraphData[row]]);
                }
              }

              // tri par nombre ascendant
              sortGraphData.sort((a: any[], b: any[]) => {
                return a[1] - b[1];
              });

              // préparation des données pour le graphique
              sortGraphData.forEach((elmnt: any[]) => {
                const key = elmnt[0];
                const val = elmnt[1];
                this.itemsList.graph.labels.push(config[key].name + ' (' + val + ')');
                this.itemsList.graph.colors.push(Common.rgbToRgba(config[key].color, 0.5));
                this.itemsList.graph.data.push(val);
              });
            }

            // on change le message à afficher à côté du titre "Ma Collection".
            this.collectionMsg = msg;
        }
    }
