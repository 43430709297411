import Common from '@/services/Common';

export class BaseModel {
    // @ts-ignore
    protected _id: string = Common.getToken();
    // @ts-ignore
    protected _uid: string = Common.getCurrentUserId();
    protected _title: string = '';
    protected _category: string = '';
    protected _note: number = -0.5;
    protected _statut: string = 'À jour';
    protected _etat: string = 'Collection';
    protected _client: string = '';
    protected _comment: string = '';
    protected _defaut: string[] = [];
    protected _image: string = '';
    protected _externalLink: string = '';
    protected _checked: boolean = false;

    // @ts-ignore
    protected _createdAt: number = Date.now();
    // @ts-ignore
    protected _updatedAt: number = Date.now();

    /**
     * @param {any} data
     * @param {boolean} duplicate
     * @param {any} category
     */
    constructor(data: any, duplicate: boolean, category: string) {
        if (data.title) { this._title = data.title; }
        if (data.image) { this._image = data.image; }
        if (data.externalLink) { this._externalLink = data.externalLink; }
        this.category = category;
        if (!duplicate) {
            // @ts-ignore
            if (data._id) { this._id = data._id; }
            // @ts-ignore
            if (data.uid) { this._uid = data.uid; }
            if (typeof data.note === 'number') { this._note = data.note; }
            if (data.statut) { this._statut = data.statut; }
            if (data.etat) { this._etat = data.etat; }
            if (data.client) { this._client = data.client; }
            if (data.comment) { this._comment = data.comment; }
            if (data.checked) { this._checked = data.checked; }
            if (data.created_at) { this._createdAt = data.created_at; }
            if (data.updated_at) { this._updatedAt = data.updated_at; }
            if (data.defaut) { this._defaut = data.defaut; }
        }
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get uid(): string {
        return this._uid;
    }

    set uid(value: string) {
        this._uid = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get category(): string {
        return this._category;
    }

    set category(value: string) {
        this._category = value;
    }

    get note(): number {
        return this._note;
    }

    set note(value: number) {
        this._note = value;
    }

    get statut(): string {
        return this._statut;
    }

    set statut(value: string) {
        this._statut = value;
    }

    get etat(): string {
        return this._etat;
    }

    set etat(value: string) {
        this._etat = value;
    }

    get client(): string {
        return this._client;
    }

    set client(value: string) {
        this._client = value;
    }

    get image(): string {
        return this._image;
    }

    set image(value: string) {
        this._image = value;
    }

    get externalLink(): string {
        return this._externalLink;
    }

    set externalLink(value: string) {
        this._externalLink = value;
    }

    get comment(): string {
        return this._comment;
    }

    set comment(value: string) {
        this._comment = value.replaceAll(/[\r\v]+/g, '');
    }

    get defaut(): string[] {
        return this._defaut;
    }

    set defaut(value: string[]) {
        this._defaut = value.sort();
    }

    get createdAt(): number {
        return this._createdAt;
    }

    set createdAt(value: number) {
        this._createdAt = value;
    }

    get updatedAt(): number {
        return this._updatedAt;
    }

    set updatedAt(value: number) {
        this._updatedAt = value;
    }
}
