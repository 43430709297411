import {BaseVideosModel} from '@/models/Videos/BaseVideosModel';
import {config} from '@/config/CategoryConfig';

export class AutresVideosItem extends BaseVideosModel {

    /**
     * @param {any} data
     * @param {boolean} duplicate
     */
    constructor(data: any = {}, duplicate: boolean = false) {
        super(data, duplicate, config.autresVideos.category);
    }
}
