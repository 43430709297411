import {config} from '@/config/CategoryConfig';
import {Crud} from '@/services/Crud';
import Common from '@/services/Common';
import {ItemFieldsConfig} from "@/config/ItemFieldsConfig";

/**
 * Regroupe toutes les fonctions utilisées dans les applications.
 */
export class CategoryFunctions {
    /**
     * Permet de dire si un item doit être afficher ou non en fonction des filtres sélectionné
     *
     * @private
     * @static
     * @param {any} searchData
     * @param {any} itemsList
     * @param {any} collectionData
     * @return {boolean}
     */
    public static filterItem(searchData: any, itemsList: any, collectionData: any): any {
        // on vérifie si on a la données de la collection et si il y a bien des fitres de sélectionnés sinon on retourne directement la liste complete
        if (!Common.checkIsNotUndifined(collectionData) && Object.keys(searchData).length === 0) {
            return itemsList;
        }

        const fields = ItemFieldsConfig.fields

        return itemsList.filter((tmpData: any) => {
            // récupération des données venant du filtre et création d'un nouvel objet non linké avec celui de provenance
            searchData = Object.assign({}, searchData);

            // on formate l'item sous le bon model
            const data = new collectionData.className(tmpData);

            // variable permettant de savoir si on va afficher ou non l'item
            let show = true;

            // si on a bien des filtres
            if (Object.keys(searchData).length > 0) {
                for (const key of Object.keys(searchData)) {
                    if (!show && !Common.checkIsNotUndifined(searchData[key]) && searchData[key] === null) {
                        break;
                    }

                    // on va supprimer le mot "List" du nom du filtre pour que ça match bien avec les champs de l'item
                    const dataKey = key.replace('List', '');
                    if (data[dataKey] === undefined) {
                        continue;
                    }

                    // on va traiter tous les filtres qui ne sont pas une note
                    if (typeof searchData[key] !== 'number') {
                        // On traite les cas spécifique
                        if (dataKey === fields.dematerialise.key) {
                            if (searchData.dematerialise === null) {
                                continue;
                            }
                            show = data.dematerialise === searchData.dematerialise;
                        } else if (key === fields.extension.key) {
                            if (searchData.extension === null) {
                                continue;
                            }
                            const filterIsExtension: boolean = searchData.extension === 'Extension';
                            show = data.extension === filterIsExtension;
                        } else {
                            // On traite les cas non spécifique
                            if (searchData[key] === '') {
                                show = true;
                            } else if (typeof searchData[key] === 'object') {
                                if (Object.keys(searchData[key]).length > 0) {
                                    if (dataKey === fields.genre.key || dataKey === fields.defaut.key) {
                                        for (const item of searchData[key]) {
                                            show = data[dataKey].indexOf(item) > -1 || item === '';
                                            if (!show) {
                                                break;
                                            }
                                        }
                                    } else {
                                        for (const item of searchData[key]) {
                                            if (key === fields.endDate.key + 'List') {
                                                show = data[dataKey].toLowerCase().includes(item.toLowerCase());
                                            } else {
                                                show = data[dataKey].toLowerCase() === item.toLowerCase();
                                            }
                                            if (show) {
                                                break;
                                            }
                                        }
                                    }
                                } else {
                                    show = true;
                                }
                            } else if (dataKey === fields.oeuvre.key && typeof data[dataKey] === 'object') {
                                show = data[dataKey].title.toLowerCase().includes(searchData[key].toLowerCase());
                            } else {
                                show = data[dataKey].toLowerCase().includes(searchData[key].toLowerCase());
                            }
                        }
                    } else if (searchData[key] > -1) {
                        if (key === fields.note.key) {
                            show = data[dataKey] === searchData[key];
                        } else if (key === fields.minPlayer.key) {
                            show = data[dataKey] >= searchData[key];
                        } else if (key === 'maxPlayer') {
                            show = data[dataKey] <= searchData[key];
                        } else if (key === fields.tempsPartie.key && searchData[key] > 0) {
                            show = data[dataKey] <= searchData[key];
                        }
                    }
                    if (!show) {
                        break;
                    }
                }
            }
            if (show) {
                return show;
            }
        });
    }

    /**
     * Permet de retourner le crud
     *
     * @public
     * @return {Crud}
     */
    public static getCrud(): Crud {
        return new Crud(process.env.VUE_APP_API_ITEMS_PATH);
    }

    /**
     * Permet de récupérer les configurations de toutes les catégories
     *
     * @private
     * @static
     * @return {any}
     */
    public static getAllConfig(): any {
        return config;
    }

    private categoryName: string;
    private _data: any;

    constructor(categoryName: string, dataList: null|any = null) {
        this.categoryName = categoryName;
        this._data = config[this.categoryName];
        this._data.dataList = dataList;
    }

    /**
     * Permet de créer un nouvel item en fonction de la class sélectionnée
     *
     * @public
     * @param {any} data
     * @return {any}
     */
    public newItem(data: any = {}): any {
        return new this._data.className(data);
    }

    /**
     * Récupérer la configuration de la catégorie sélectionnée
     *
     * @public
     * @return {any}
     */
    public get data(): any {
        return this._data;
    }
}
