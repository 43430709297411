import {CategoryFunctions} from '@/services/CategoryFunctions';
import Common from '@/services/Common';
import {Crud} from '@/services/Crud';

export default {
    namespaced: true,
    state: {
        searchData: {},
        categoryFunction: '',
        collectionUid: '',
        itemsList: [],
        itemsFullList: [],
        itemsOwnFullList: [],
        dataList: [],
    },
    getters: {
        getCollectionData: (state: any) => state.categoryFunction.data,
        getItemsList: (state: any) => state.itemsList,
        getItemsFullList: (state: any) => state.itemsFullList,
        getOwnItemsFullList: (state: any) => state.itemsOwnFullList,
        getSearchData: (state: any) => state.searchData,
    },
    mutations: {
        SET_SEARCH_DATA: (state: any, data: any) => {
            state.searchData = {};
            state.searchData = data;
        },
        SET_CATEGORY_FUNCTION: (state: any, data: any) => {
            state.categoryFunction = data;
        },
        SET_ITEMS_LIST: (state: any, data: any) => {
            state.itemsList = data;
        },
        SET_ITEMS_FULL_LIST: (state: any, data: any) => {
            state.itemsFullList = data;
        },
        SET_OWN_ITEMS_FULL_LIST: (state: any, data: any) => {
            state.itemsOwnFullList = data;
        },
    },
    actions: {
        setCurrentCategoryFunc: async ({state, commit, dispatch}: any, name: string) => {
            const selector: any = {
                label: { $exists: true },
                category: {
                    $elemMatch: {
                        $eq: name,
                    },
                },
            };

            const crud = new Crud(process.env.VUE_APP_API_LISTS_PATH);
            crud.getFullListGroupByType(selector).then((data: any) => {
                const catFunc: CategoryFunctions = new CategoryFunctions(name, data);
                commit('SET_CATEGORY_FUNCTION', catFunc);
                dispatch('setItemsList');
            });
        },

        createItem: ({state}: any, data: any) => {
            return CategoryFunctions.getCrud().create(data);
        },

        updateItem: ({state}: any, data: any) => {
            return CategoryFunctions.getCrud().update(data);
        },

        deleteItem: ({state}: any, id: string) => {
            return CategoryFunctions.getCrud().delete(id);
        },

        setSearchData: ({state, commit}: any, data: any = {}) => {
            commit('SET_SEARCH_DATA', data);
        },

        getItem: ({state}: any, id: string) => {
            return CategoryFunctions.getCrud().getItem(id);
        },

        setItemsList: async ({state, commit, dispatch}: any) => {
            let uid: null | string = null;

            await dispatch('general/checkCollectionUser', null, {root: true}).then((getUid: any) => {
                uid = getUid;
            });

            CategoryFunctions.getCrud().getListByUser(
                {
                    title: {$exists: true},
                    category: state.categoryFunction.data.category,
                },
                [{title: 'asc'}],
                null,
                uid,
            ).then((list: any) => {
                commit('SET_ITEMS_LIST', list);
            });
        },

        setItemsFullList: ({state, commit}: any) => {
            CategoryFunctions.getCrud().getFullList({
                title: {$exists: true},
                category: state.categoryFunction.data.category,
            }).then((list: any) => {
                commit('SET_ITEMS_FULL_LIST', list);
            });
        },

        setOwnItemsFullList: async ({state, commit, dispatch}: any, selector: any = {title: {$exists: true}}) => {
            let userid: null | string = null;

            await dispatch('general/checkCollectionUser', null, {root: true}).then((getUid: any) => {
                userid = getUid ? getUid : Common.getCurrentUserId();
            });

            await CategoryFunctions.getCrud().getListByUser(
                selector,
                ['title'],
                [
                    '_id', 'title', 'category',
                ],
                userid,
            ).then((list: any) => {
                commit('SET_OWN_ITEMS_FULL_LIST', list);
            });
        },
    },
    strict: true,
};
