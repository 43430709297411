import {BaseJeuxModel} from '@/models/Jeux/BaseJeuxModel';
import {config} from '@/config/CategoryConfig';

export class VideoGamesItem extends BaseJeuxModel {
    private _console: string = 'Playstation 5';
    private _dematerialise: boolean = false;
    private _extension: boolean = false;
    private _editeur: string = '';
    private _developpeur: string = '';
    private _endDate: string = '';
    private _genre: string[] = [];
    private _langue: string = 'Français';

    /**
     * @param {any} data
     * @param {boolean} duplicate
     */
    constructor(data: any = {}, duplicate: boolean = false) {
        super(data, duplicate, config.videoGames.category);
        if (data.genre) { this._genre = data.genre; }
        if (data.console) { this._console = data.console; }
        if (data.dematerialise) { this._dematerialise = data.dematerialise; }
        if (data.extension) { this._extension = data.extension; }
        if (data.editeur) { this._editeur = data.editeur; }
        if (data.developpeur) { this._developpeur = data.developpeur; }
        if (data.langue) { this._langue = data.langue; }

        if (!duplicate) {
            if (!data.statut) { this._statut = 'À faire'; }
            if (data.endDate) { this._endDate = data.endDate; }
        }
    }

    get genre(): string[] {
        return this._genre;
    }

    set genre(value: string[]) {
        this._genre = value.sort();
    }

    get langue(): string {
        return this._langue;
    }

    set langue(value: string) {
        this._langue = value;
    }

    get console(): string {
        return this._console;
    }

    set console(value: string) {
        this._console = value;
    }

    get dematerialise(): boolean {
        return this._dematerialise;
    }

    set dematerialise(value: boolean) {
        this._dematerialise = value;
    }

    get extension(): boolean {
        return this._extension;
    }

    set extension(value: boolean) {
        this._extension = value;
    }

    get endDate(): string {
        return this._endDate;
    }

    set endDate(value: string) {
        this._endDate = value;
    }

    get editeur(): string {
        return this._editeur;
    }

    set editeur(value: string) {
        this._editeur = value;
    }

    get developpeur(): string {
        return this._developpeur;
    }

    set developpeur(value: string) {
        this._developpeur = value;
    }
}
