import router from '@/router';
import {Vue} from 'vue-property-decorator';

/**
 * Regroupe toutes les fonctions communes
 */
export default class Common {
    /**
     * Permet de vérifier si on est visiteur du profile
     *
     * @public
     * @static
     * @return {boolean}
     */
    public static getIsVisitor(): boolean {
        let isVisitor: boolean = false;
        // on vérifie si on est visiteur du profile
        if (Common.checkIsNotUndifined(router.currentRoute.params.username)) {
            isVisitor = true;
        }
        return isVisitor;
    }

    /**
     * On décode et récupére le cookie demandé
     *
     * @public
     * @static
     * @param {string} cname
     * @return {string|null}
     */
    public static getCookie(cname: string): string | null {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (const row of ca) {
            let c = row;
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    }

    /**
     * Permet de récupérer tous les paramètres dans une url. Retourne un objet :
     * {
     *     key1: [param1, param2, ...]
     * }
     *
     * @public
     * @static
     * @return {any}
     */
    public static getUrlParams(): any {
        const result: any = {
            * [Symbol.iterator]() {
                const properties = Object.keys(this);
                for (const i of properties) {
                    yield [i, this[i]];
                }
            },
        };

        // récupére l'url et création d'un objet URL
        const urlString = window.location.href;
        const url = new URL(urlString);

        // boucle sur chaque clé
        for (const key of url.searchParams.keys()) {
            // on vérifie que la clé n'a pas été déjà ajouté dans l'objet de retour
            if (!this.checkIsNotUndifined(result[key])) {
                result[key] = url.searchParams.getAll(key);
            }
        }
        return result;
    }

    /**
     * Génére un lien en fonction des "clé: valeur" envoyées sous forme de tableau.
     *
     * @public
     * @static
     * @param {any[]} data
     * @param {boolean} withUrl
     * @return {string}
     */
    public static generateLinkWithKey(data: any, withUrl: boolean = false): string {
        // on prépare l'url du lien
        let link: string = window.location.pathname + '?';

        // si avec l'url, alors on rajoute "https://g-vu.ovh/"
        if (withUrl) {
            link = process.env.VUE_APP_URL + link;
        }

        // on fait une boocle sur les données pour générer le lien
        for (const key in data) {
            if (data[key] === undefined ||
                data[key] === '' ||
                data[key] === null ||
                (Array.isArray(data[key]) && data[key].length === 0)) {
                continue;
            }
            // fonction permettant d'ajouter au lien, une clé et une valeur
            const addVal: any = (linkTmp: string, keyTmp: string, valTmp: number | string) => linkTmp + keyTmp + '=' + valTmp + '&';

            // on vérifie si nos données sont sous forme de tableau pour les rajouter sous la même clé
            if (Array.isArray(data[key])) {
                data[key].forEach((val: any) => link = addVal(link, key, val));
            } else {
                link = addVal(link, key, data[key]);
            }
        }

        // on supprime le dernier "&" du lien puis on le retourne
        return link.slice(0, -1);
    }

    /**
     * On retourne les models en retirant les underscore des paramètres pour que ça soit adapté au format de la bdd
     *
     * @public
     * @static
     * @param {any} itemTmp
     * @return {any}
     */
    public static formatObjectToNoUnderscore(itemTmp: any): any {
        const item: any = Object.assign({}, itemTmp);
        const result: any = {};
        for (const key in item) {
            if (key === '_createdAt') {
                result.created_at = item[key];
            } else if (key === '_updatedAt') {
                result.updated_at = item[key];
            } else if (key === '_id') {
                result[key] = item[key];
            } else {
                result[key.substring(1)] = item[key];
            }
        }

        return result;
    }

    /**
     * Permet de rajouter l'opacité sur une couleur rgb
     * le taux doit se situer entre 0 et 1
     *
     * @public
     * @static
     * @param {string} rgb
     * @param {number} taux
     * @return {string}
     */
    public static rgbToRgba(rgb: string, taux: number): string {
        if (taux >= 0 && taux <= 1) {
            return rgb.replace(')', ',' + taux + ')');
        }
        return rgb;
    }

    /**
     * Vérifie si une valeur est non défini
     *
     * @public
     * @static
     * @param {any} value
     * @return {boolean}
     */
    public static checkIsNotUndifined(value: any): boolean {
        return value !== undefined;
    }

    /**
     * Vérifie si une valeur string n'est pas vide
     *
     * @public
     * @static
     * @param {null|string} value
     * @return {boolean}
     */
    public static stringIsNotEmpty(value: null|string): boolean {
        return value !== null && value.replaceAll(/\s/g, '') !== '';
    }

    /**
     *  Génére l'ancre d'un item en fonction de son titre et de sa langue
     *
     * @public
     * @static
     * @param {string} title
     * @param {string} lang
     * @return {string}
     */
    public static getAncre(title: string, lang: string): string {
        let result = title.replace(/ /g, '-');
        if (Common.checkIsNotUndifined(lang)) {
            result += '-' + lang;
        }
        return result;
    }

    /**
     * Format une date envoyé au format timestamp et la format avec ou sans heure, minute et seconde ou seulement avec.
     *
     * @public
     * @static
     * @param {any} getDate
     * @param {boolean} withTime
     * @param {boolean} onlyTime
     * @return {string}
     */
    public static formatDate(getDate: any, withTime: boolean = true, onlyTime: boolean = false): string {
        if (typeof getDate === 'number') {
            getDate = getDate * 1;
        }
        const formatDate = new Date(getDate);

        let finalDate = ('0' + formatDate.getDate()).slice(-2) +
            '/' +
            ('0' + (formatDate.getMonth() + 1)).slice(-2) +
            '/' +
            formatDate.getFullYear();

        if (withTime) {
            finalDate = finalDate + ' à ' +
                ('0' + formatDate.getHours()).slice(-2) + 'h' +
                ('0' + formatDate.getMinutes()).slice(-2);
        } else if (onlyTime) {
            finalDate = ('0' + formatDate.getHours()).slice(-2) + 'h' +
                ('0' + formatDate.getMinutes()).slice(-2);
        }

        return finalDate;
    }

    /**
     * Formate la date des items RSS
     *
     * @public
     * @static
     * @param {any} tdate
     * @return {string}
     */
    public static getRssFormatDate(tdate: any): string {
        const systemDate: any = new Date(Date.parse(tdate));
        const userDate: any = new Date();

        const diff = Math.floor((userDate - systemDate) / 1000);
        if (diff < 59) {
            return diff + 's';
        }

        if (diff <= 3540) {
            return Math.round(diff / 60) + 'm';
        }

        if (diff <= 86400) {
            return Math.round(diff / 3600) + 'h';
        }

        if (diff < 604800) {
            return Math.round(diff / 86400) + 'd';
        }

        return systemDate.toString().substring(4, 10);
    }

    /**
     * Dans certains cas les datas sont transmis sous forme de liste d'objets et on cherche à récupérer la liste
     * des strings pour les select.
     * Chaque objet doit respecter ce format sinon une erreur se produira :
     * {
     *     key: {
     *         name: '',
     *         key1: '',
     *         key2: etc...
     *     }
     * }
     *
     * @public
     * @static
     * @param {any} list
     * @return {string[]}
     */
    public static objectDataToStringList(list: any): string[] {
        const stringList: string[] = [];

        for (const key in list) {
            if (list[key]) {
                stringList.push(list[key].name);
            }
        }

        return stringList;
    }

    /**
     * Première lettre d'un mot en majuscule
     *
     * @public
     * @static
     * @param {string} word
     * @return {string}
     */
    public static firstLetterUppercase(word: string): string {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    /**
     * Permet de convertir un string ("true" / "false") en boolean.
     *
     * @param {string} toConvert
     * @return {boolean}
     */
    public static stringToBoolean(toConvert: string): boolean|null {
        // on vérifie que l'on envoi bien un boolean parsé en string
        if (String(toConvert) !== 'true' && String(toConvert) !== 'false') {
            throw new Error('Le string ne comporte aucun boolean');
        }

        return toConvert === 'true';
    }

    /**
     * Permet de convertir les anciennes formes d'oeuvre original (string) en nouvelle (objet)
     *
     * @public
     * @static
     * @param {string} oeuvre
     * @return {any}
     */
    public static checkAndOeuvreOriginalLegacy(oeuvre: any): any {
        if (typeof oeuvre === 'string') {
            return {
                title: oeuvre,
                category: '',
                inCollection: false,
            };
        }

        return oeuvre;
    }

    /**
     * Permet de calculer le pourcentage
     *
     * @public
     * @static
     * @param {number} value
     * @param {number} onValue
     * @return {number}
     */
    public static calculPercent(value: number, onValue: number): number {
        const percent = Math.round((100 * value) / onValue);
        return !isFinite(percent) ? 0 : percent;
    }

    /**
     * Récupère l'id de l'utilisateur connecté
     *
     * @public
     * @static
     * @return {string}
     */
    public static getCurrentUserId(): string {
        if (!this.checkIsNotUndifined(Vue.prototype.$keycloak.subject)) {
            throw new Error('Aucun id utilisateur trouvé.');
        }

        return Vue.prototype.$keycloak.subject;
    }

    /**
     *  Permet de générer des tokens
     *
     *  @public
     *  @static
     *  @return {string}
     */
    public static getToken(): string {
        const key = () => Math.random().toString(36).substr(2);

        return key() + key() + key() + key();
    }

    /**
     * Permet de trier un tableau d'objet en ascendant ou descendant à partir d'un parametre donné.
     *
     * @public
     * @static
     * @param {any[]} list
     * @param {string} prop
     * @param {string} sort
     * @return {any[]}
     */
    public static sortObjectArray(list: any[], prop: string, sort: string): any[] {
        return list.sort((a: any, b: any) => {
            let valA: string|number = a[prop];
            let valB: string|number = b[prop];

            if (typeof valA === 'string' && typeof valB === 'string') {
                valA = valA.toLowerCase();
                valB = valB.toLowerCase();
            }
            if (sort === 'desc') {
                return (valA < valB) ? 1 : ((valB < valA) ? -1 : 0);
            }
            return (valA > valB) ? 1 : ((valB > valA) ? -1 : 0);
        });
    }

    /**
     * Permet de copier un object et de le dissocier de l'autre afin d'avoir deux object bien distints.
     *
     * @public
     * @static
     * @param {any} data
     * @return {any}
     */
    public static unlinkTwoObjects(data: any): any {
        return JSON.parse(JSON.stringify(data));
    }

    /**
     * Récupére la première ligne filtré
     *
     * @param {any[]} list
     * @param {string} param
     * @param {number|string} data
     * @return {any}
     */
    public static getUniqueRowFromArray(list: any[], param: string, data: number|string): any {
        const result: any[] = list.filter((row: any) => {
           if (row[param] === data) {
               return row;
           }
        });

        return result[0];
    }
}
