import Vue from 'vue';
import axios from 'axios';
import BootstrapVue from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {initOptions} from '@/config/Keycloak';
import '@/assets/js/font-awesome';

// @ts-ignore
import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
// @ts-ignore
import vSelect from 'vue-select';
import VueSmoothScroll from 'vue2-smooth-scroll';
// @ts-ignore
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-select/dist/vue-select.css';
import './assets/css/style.css';

Vue.component('v-select', vSelect);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueSmoothScroll, {
    duration: 1500,
});


function tokenInterceptor() {
    axios.interceptors.request.use((config) => {
        // @ts-ignore
        config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
}

Vue.use(VueKeyCloak, {
    config: initOptions,
    init: {
        onLoad: 'login-required',
        checkLoginIframe: false,
    },
    onReady: () => {
        if (Vue.prototype.$keycloak.authenticated) {
            tokenInterceptor();
        }

        new Vue({
            router,
            store,
            render: (h) => h(App),
        }).$mount('#app');
    },
});
