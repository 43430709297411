import {BaseModel} from '@/models/BaseModel';
import {config} from '@/config/CategoryConfig';
import Common from '@/services/Common';

export class GoodiesItem extends BaseModel {
    private _fabricant: string = '';
    private _type: string = 'Figurine';
    private _oeuvre: object = {
        title: '',
        category: '',
        inCollection: true,
    };
    private _prix: number = 0;
    private _monnaie: string = '€';
    private _releaseDate: string = '';
    private _genre: string[] = [];

    /**
     * @param {any} data
     * @param {boolean} duplicate
     */
    constructor(data: any = {}, duplicate: boolean = false) {
        super(data, duplicate, config.goodies.category);
        if (data.genre) { this._genre = data.genre; }
        if (data.fabricant) { this._fabricant = data.fabricant; }
        if (data.type) { this._type = data.type; }
        if (data.oeuvre) { this._oeuvre = Common.checkAndOeuvreOriginalLegacy(data.oeuvre); }
        if (data.prix) { this._prix = data.prix; }
        if (data.monnaie) { this._monnaie = data.monnaie; }
        if (data.releaseDate) { this._releaseDate = data.releaseDate; }
    }

    get fabricant(): string {
        return this._fabricant;
    }

    set fabricant(value: string) {
        this._fabricant = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get oeuvre(): object {
        return this._oeuvre;
    }

    set oeuvre(value: object) {
        this._oeuvre = value;
    }

    get prix(): number {
        return this._prix;
    }

    set prix(value: number) {
        this._prix = value;
    }

    get monnaie(): string {
        return this._monnaie;
    }

    set monnaie(value: string) {
        this._monnaie = value;
    }

    get releaseDate(): string {
        return this._releaseDate;
    }

    set releaseDate(value: string) {
        this._releaseDate = value;
    }

    get genre(): string[] {
        return this._genre;
    }

    set genre(value: string[]) {
        this._genre = value;
    }
}
