import {BaseJeuxModel} from '@/models/Jeux/BaseJeuxModel';
import {config} from '@/config/CategoryConfig';

export class ConsolesItem extends BaseJeuxModel {
    protected _statut: string = 'Fonctionne';
    private _console: string = 'Playstation 5';
    private _constructeur: string = '';
    private _type: string = '';
    private _edition: string = '';
    private _support: string = '';
    private _origine: string = 'Japon';

    /**
     * @param {any} data
     * @param {boolean} duplicate
     */
    constructor(data: any = {}, duplicate: boolean = false) {
        super(data, duplicate, config.consoles.category);

        if (data.console) { this._console = data.console; }
        if (data.constructeur) { this._constructeur = data.constructeur; }
        if (data.type) { this._type = data.type; }
        if (data.edition) { this._edition = data.edition; }
        if (data.support) { this._support = data.support; }
        if (data.origine) { this._origine = data.origine; }
        if (data.statut) { this._statut = data.statut; }
    }


    get console(): string {
        return this._console;
    }

    set console(value: string) {
        this._console = value;
    }

    get constructeur(): string {
        return this._constructeur;
    }

    set constructeur(value: string) {
        this._constructeur = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get edition(): string {
        return this._edition;
    }

    set edition(value: string) {
        this._edition = value;
    }

    get support(): string {
        return this._support;
    }

    set support(value: string) {
        this._support = value;
    }

    get origine(): string {
        return this._origine;
    }

    set origine(value: string) {
        this._origine = value;
    }
}
