import {BaseModel} from '@/models/BaseModel';

export class BaseVideosModel extends BaseModel {
    protected _support: string = 'Blu-ray';
    protected _vod: string = '';
    protected _realisateur: string = '';
    protected _studio: string = '';
    protected _releaseDate: string = '';
    protected _endDate: string = '';
    protected _genre: string[] = [];
    protected _langue: string = 'Français';

    /**
     * @param {any} data
     * @param {boolean} duplicate
     * @param {string} category
     */
    constructor(data: any = {}, duplicate: boolean = false, category: string) {
        super(data, duplicate, category);
        if (data.genre) { this._genre = data.genre; }
        if (data.support) { this._support = data.support; }
        if (data.vod) { this._vod = data.vod; }
        if (data.realisateur) { this._realisateur = data.realisateur; }
        if (data.studio) { this._studio = data.studio; }
        if (data.releaseDate) { this._releaseDate = data.releaseDate; }
        if (data.langue) { this._langue = data.langue; }

        if (!duplicate) {
            if (data.endDate) { this._endDate = data.endDate; }
            if (!data.statut) { this._statut = 'À voir'; }
        }
    }

    get support(): string {
        return this._support;
    }

    set support(value: string) {
        this._support = value;
    }

    get vod(): string {
        return this._vod;
    }

    set vod(value: string) {
        this._vod = value;
    }

    get genre(): string[] {
        return this._genre;
    }

    set genre(value: string[]) {
        this._genre = value;
    }

    get langue(): string {
        return this._langue;
    }

    set langue(value: string) {
        this._langue = value;
    }

    get realisateur(): string {
        return this._realisateur;
    }

    set realisateur(value: string) {
        this._realisateur = value;
    }

    get studio(): string {
        return this._studio;
    }

    set studio(value: string) {
        this._studio = value;
    }

    get releaseDate(): string {
        return this._releaseDate;
    }

    set releaseDate(value: string) {
        this._releaseDate = value;
    }

    get endDate(): string {
        return this._endDate;
    }

    set endDate(value: string) {
        this._endDate = value;
    }
}
