import {BaseLivresModel} from '@/models/Livres/BaseLivresModel';
import {config} from '@/config/CategoryConfig';

export class BdItem extends BaseLivresModel {
    private _dessinateur: string = '';
    private _scenariste: string = '';

    /**
     * @param {any} data
     * @param {boolean} duplicate
     */
    constructor(data: any = {}, duplicate: boolean = false) {
        super(data, duplicate, config.bd.category, '2024');
        if (data.dessinateur) { this._dessinateur = data.dessinateur; }
        if (data.scenariste) { this._scenariste = data.scenariste; }
    }

    get dessinateur(): string {
        return this._dessinateur;
    }

    set dessinateur(value: string) {
        this._dessinateur = value;
    }

    get scenariste(): string {
        return this._scenariste;
    }

    set scenariste(value: string) {
        this._scenariste = value;
    }
}
