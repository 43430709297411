import {BaseVideosModel} from '@/models/Videos/BaseVideosModel';
import {config} from '@/config/CategoryConfig';

export class SeriesItem extends BaseVideosModel {
    private _saison: number = 1;
    private _episode: number = 1;

    /**
     * @param {any} data
     * @param {boolean} duplicate
     */
    constructor(data: any = {}, duplicate: boolean = false) {
        super(data, duplicate, config.series.category);

        if (!duplicate) {
            if (data.saison) { this._saison = data.saison; }
            if (data.episode) { this._episode = data.episode; }
        }
    }

    get saison(): number {
        return this._saison;
    }

    set saison(value: number) {
        this._saison = value;
    }

    get episode(): number {
        return this._episode;
    }

    set episode(value: number) {
        this._episode = value;
    }
}
