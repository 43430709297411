import {Crud} from '@/services/Crud';
import Common from '@/services/Common';
import router from '@/router';

export default {
    namespaced: true,
    state: {
        modalData: {},
        loadingStatus: true,
        crudCategory: new Crud(process.env.VUE_APP_API_ITEMS_PATH),
        crudChangeLog: new Crud(process.env.VUE_APP_API_CHANGELOGS_PATH),
        userInfos: '',
        changeLogList: [],
        toastData: {
            show: false,
            title: '',
            msg: '',
        },
    },
    getters: {
        getUserInfos: (state: any) => state.userInfos,
        getModalData: (state: any) => state.modalData,
        getLoadingStatus: (state: any) => state.loadingStatus,
        getSharedUserList: (state: any) => state.crudCategory.getUsersList(),
        getChangeLog: (state: any) => state.changeLogList,
        getToastData: (state: any) => state.toastData,
    },
    mutations: {
        SET_MODAL_DATA: (state: any, data: any) => {
            state.modalData = data;
        },
        SET_LOADING_STATUS: (state: any, value: boolean) => {
            state.loadingStatus = value;
        },
        SET_USER_INFOS: (state: any, data: any) => {
            state.userInfos = data;
        },
        SET_CHANGE_LOG: (state: any, data: any) => {
            state.changeLogList = data;
        },
        SET_TOAST_DATA: (state: any, data: any) => {
            state.toastData = data;
        },
    },
    actions: {
        setModalData: ({state, commit}: any, data: any) => {
            commit('SET_MODAL_DATA', data);
        },

        setLoadingStatus: ({state, commit}: any, value: boolean) => {
            commit('SET_LOADING_STATUS', value);
        },
        getRss: ({state}: any, data: any) => {
            return state.crudCategory.getRss(data.url, data.size).then((items: any) => {
                return items;
            });
        },
        deleteSelectedImg: async ({state, dispatch}: any, imgLink: string) => {
            // on vérifie que c'est bien un fichier stocké sur g-vu
            if (!imgLink.includes(process.env.VUE_APP_URL_IMG)) {
                return;
            }
            // on récupére la liste des fiches ayant cette image
            await state.crudCategory.getFullList({
                title: {$exists: true},
                image: imgLink,
            }).then(async (resultFiches: any) => {
                // on récupére la liste des models ayant cette image
                const crud = new Crud(process.env.VUE_APP_API_MODELS_PATH);
                await crud.getFullList({
                    title: {$exists: true},
                    image: imgLink,
                }).then(async (resultModels: any) => {
                    const nbrRes: number = resultFiches.length + resultModels.length;

                    // si l'image n'est que sur une fiche, on la supprime
                    if (nbrRes === 1) {
                        // On récupere que le chemin ("/media/yyyy/m/d")
                        const imgPath: string = imgLink.replace(process.env.VUE_APP_URL_IMG, '');

                        await state.crudCategory.deleteImage(imgPath);
                    }
                });
            });
        },
        checkCollectionUser: async ({getters}: any) => {
            let uid: null|string = null;

            // on vérifie si un autre utilisateur est sélectionné
            if (Common.checkIsNotUndifined(router.currentRoute.params.username)) {
                // on récupére l'utilisateur
                const username = router.currentRoute.params.username;

                await getters.getSharedUserList.then((list: any) => {
                    for (const idx in list) {
                        if (list[idx].username === username) {
                            uid = list[idx].id;
                        }
                    }

                    // dans le cas ou on ne trouve aucun utilisateur
                    if (uid === null) {
                        router.push('/refresh');
                    }
                });
            }

            return uid;
        },
        addChangeLog: async ({state, commit, dispatch}: any, data: any) => {
            data._id = Common.getToken();

            await state.crudChangeLog.create(data);
            await state.crudChangeLog.getListByUser({date: {$exists: true}}, [{date: 'desc'}]).then((list: any) => {
                if (list[50]) {
                    state.crudChangeLog.delete(list[50]._id);
                }
            });
            dispatch('setChangeLog');
        },
        initUserInfos: ({state, commit}: any, data: any) => {
            commit('SET_USER_INFOS', data);
        },
        setChangeLog: async ({state, commit, dispatch}: any) => {
            let uid: null|string = null;
            await dispatch('checkCollectionUser').then((getUid: any) => {
                uid = getUid;
            });

            state.crudChangeLog.getListByUser(
                {date: {$exists: true}},
                [{date: 'desc'}],
                null,
                uid,
            ).then((list: any) => {
                commit('SET_CHANGE_LOG', list);
            });
        },
        setToastData: ({state, commit}: any, data: any) => {
            commit('SET_TOAST_DATA', data);
        },

    },
    strict: true,
};
