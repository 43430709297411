import {BaseLivresModel} from '@/models/Livres/BaseLivresModel';
import {config} from '@/config/CategoryConfig';

export class RomanItem extends BaseLivresModel {
    private _format: string = 'Édition normal';
    private _auteur: string = '';

    /**
     * @param {any} data
     * @param {boolean} duplicate
     */
    constructor(data: any = {}, duplicate: boolean = false) {
        super(data, duplicate, config.romans.category, 'Actes Sud');
        if (data.format) { this._format = data.format; }
        if (data.auteur) { this._auteur = data.auteur; }
    }

    get format(): string {
        return this._format;
    }

    set format(value: string) {
        this._format = value;
    }

    get auteur(): string {
        return this._auteur;
    }

    set auteur(value: string) {
        this._auteur = value;
    }
}
