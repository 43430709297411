import {BaseModel} from '@/models/BaseModel';

export class BaseJeuxModel extends BaseModel {
    protected _releaseDate: string = '';

    /**
     * @param {any} data
     * @param {boolean} duplicate
     * @param {string} category
     */
    constructor(data: any = {}, duplicate: boolean = false, category: string) {
        super(data, duplicate, category);
        if (data.releaseDate) { this._releaseDate = data.releaseDate; }
    }

    get releaseDate(): string {
        return this._releaseDate;
    }

    set releaseDate(value: string) {
        this._releaseDate = value;
    }
}
