import {BaseLivresModel} from '@/models/Livres/BaseLivresModel';
import {config} from '@/config/CategoryConfig';
import Common from '@/services/Common';

export class ArtbookItem extends BaseLivresModel {
    private _dessinateur: string = '';
    private _oeuvre: object = {
        title: '',
        category: '',
        inCollection: true,
    };

    /**
     * @param {any} data
     * @param {boolean} duplicate
     */
    constructor(data: any = {}, duplicate: boolean = false) {
        super(data, duplicate, config.artbook.category, 'Futabasha');
        if (data.dessinateur) { this._dessinateur = data.dessinateur; }
        if (data.oeuvre) { this._oeuvre = Common.checkAndOeuvreOriginalLegacy(data.oeuvre); }
    }

    get dessinateur(): string {
        return this._dessinateur;
    }

    set dessinateur(value: string) {
        this._dessinateur = value;
    }

    get oeuvre(): object {
        return this._oeuvre;
    }

    set oeuvre(value: object) {
        this._oeuvre = value;
    }
}
