import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import {config} from '@/config/CategoryConfig';

const artbook = () => import( './views/Livres/Artbook.vue');
const autres = () => import( './views/Livres/Autres.vue');
const bd = () => import( './views/Livres/Bd.vue');
const comics = () => import( './views/Livres/Comics.vue');
const mangas = () => import( './views/Livres/Mangas.vue');
const roman = () => import( './views/Livres/Roman.vue');
const films = () => import( './views/Videos/Films.vue');
const series = () => import( './views/Videos/Series.vue');
const autresVideos = () => import( './views/Videos/AutresVideos.vue');
const consoles = () => import( './views/Jeux/Consoles.vue');
const videoGames = () => import( './views/Jeux/VideoGames.vue');
const jeuxSociete = () => import( './views/Jeux/JeuxSociete.vue');
const musiques = () => import( '@/views/Musiques.vue');
const goodies = () => import( '@/views/Goodies.vue');
const refresh = () => import( '@/views/Refresh.vue');

Vue.use(Router);


// init page qui sont aussi utilisé pour le partage utilisateur
const routesList: any[] = [
    {
        path: '/home',
        name: 'home',
        component: Home,
    },
    // LIVRES ROUTERS
    {
        path: config.artbook.rootLink,
        name: config.artbook.name,
        component: artbook,
        props: true,
    },
    {
        path: config.autresLivres.rootLink,
        name: config.autresLivres.name,
        component: autres,
        props: true,
    },
    {
        path: config.bd.rootLink,
        name: config.bd.name,
        component: bd,
        props: true,
    },
    {
        path: config.comics.rootLink,
        name: config.comics.name,
        component: comics,
        props: true,
    },
    {
        path: config.mangas.rootLink,
        name: config.mangas.name,
        component: mangas,
        props: true,
    },
    {
        path: config.romans.rootLink,
        name: config.romans.name,
        component: roman,
        props: true,
    },

    // VIDEOS ROUTERS
    {
        path: config.films.rootLink,
        name: config.films.name,
        component: films,
        props: true,
    },
    {
        path: config.series.rootLink,
        name: config.series.name,
        component: series,
        props: true,
    },
    {
        path: config.autresVideos.rootLink,
        name: config.autresVideos.name,
        component: autresVideos,
        props: true,
    },

    // JEUX ROUTERS
    {
        path: config.consoles.rootLink,
        name: config.consoles.name,
        component: consoles,
        props: true,
    },
    {
        path: config.videoGames.rootLink,
        name: config.videoGames.name,
        component: videoGames,
        props: true,
    },
    {
        path: config.jeuxSociete.rootLink,
        name: config.jeuxSociete.name,
        component: jeuxSociete,
        props: true,
    },

    // AUTRES ROUTERS
    {
        path: config.musique.rootLink,
        name: config.musique.name,
        component: musiques,
        props: true,
    },
    {
        path: config.goodies.rootLink,
        name: config.goodies.name,
        component: goodies,
        props: true,
    },
    {
        path: '/refresh',
        name: 'Refresh',
        component: refresh,
        props: true,
    },
];

// on génere les mêmes routes mais avec "user"
routesList.forEach((route: any) => {
    const tmpRoute: any = Object.assign({}, route);
    tmpRoute.path = '/user/:username' + tmpRoute.path;
    tmpRoute.name = 'user' + tmpRoute.name;
    routesList.push(tmpRoute);
});

// On rajoute les routes qui n'ont pas d'interet à être dans la partie partage utilisateur
routesList.push(
    {
        path: '*',
        redirect: '/home',
    },
);

const router = new Router({
    mode: 'history',
    routes: routesList,
});


export default router;
