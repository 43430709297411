export default class TomeItem {
    private _title: string = 'Tome 1';
    private _edition: string = 'Normal';
    private _lu: boolean = false;
    private _endDate: string = '';
    private _note: number = 0;
    private _defaut: string[] = [];

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get edition(): string {
        return this._edition;
    }

    set edition(value: string) {
        this._edition = value;
    }

    get lu(): boolean {
        return this._lu;
    }

    set lu(value: boolean) {
        this._lu = value;
    }

    get endDate(): string {
        return this._endDate;
    }

    set endDate(value: string) {
        this._endDate = value;
    }


    get note(): number {
        return this._note;
    }

    set note(value: number) {
        this._note = value;
    }

    get defaut(): string[] {
        return this._defaut;
    }

    set defaut(value: string[]) {
        this._defaut = value.sort();
    }
}
