import {BaseVideosModel} from '@/models/Videos/BaseVideosModel';
import {config} from '@/config/CategoryConfig';

export class FilmsItem extends BaseVideosModel {
    /**
     * @param {any} data
     * @param {boolean} duplicate
     */
    constructor(data: any = {}, duplicate: boolean = false) {
        super(data, duplicate, config.films.category);
    }
}
