import {BaseModel} from '@/models/BaseModel';
import TomeItem from '@/models/Livres/Composants/TomeItem';

export class BaseLivresModel extends BaseModel {
    protected _editeur: string;
    protected _langue: string = 'Français';
    private _genre: string[] = [];
    private _tomes: any = {
        nbrTome: 1,
        nbrTomeLu: 0,
        items: [
            new TomeItem(),
        ],
    };

    /**
     * @param {any} data
     * @param {boolean} duplicate
     * @param {string} category
     * @param {string} defaultEditor string
     */
    constructor(data: any = {}, duplicate: boolean = false, category: string, defaultEditor: string = '') {
        super(data, duplicate, category);
        if (data.langue) { this._langue = data.langue; }
        if (data.editeur) { this._editeur = data.editeur; } else { this._editeur = defaultEditor; }
        if (data.genre) { this._genre = data.genre; }

        if (!duplicate) {
            if (data.tomes) { this._tomes = data.tomes; }
        }
    }

    get editeur(): string {
        return this._editeur;
    }

    set editeur(value: string) {
        this._editeur = value;
    }

    get langue(): string {
        return this._langue;
    }

    set langue(value: string) {
        this._langue = value;
    }

    get tomes(): any {
        return this._tomes;
    }

    set tomes(value: any) {
        this._tomes = value;
    }

    get genre(): string[] {
        return this._genre;
    }

    set genre(value: string[]) {
        this._genre = value.sort();
    }
}
