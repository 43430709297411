import {BaseModel} from '@/models/BaseModel';
import {config} from '@/config/CategoryConfig';

export class MusiquesItem extends BaseModel {
    private _support: string = 'CD';
    private _artiste: string = '';
    private _label: string = '';
    private _releaseDate: string = '';
    private _endDate: string = '';
    private _genre: string[] = [];
    private _langue: string = 'Français';

    /**
     * @param {any} data
     * @param {boolean} duplicate
     */
    constructor(data: any = {}, duplicate: boolean = false) {
        super(data, duplicate, config.musique.category);
        if (data.genre) { this._genre = data.genre; }
        if (data.support) { this._support = data.support; }
        if (data.artiste) { this._artiste = data.artiste; }
        if (data.label) { this._label = data.label; }
        if (data.releaseDate) { this._releaseDate = data.releaseDate; }
        if (data.langue) { this._langue = data.langue; }

        if (!duplicate) {
            if (!data.statut) { this._statut = 'À écouter'; }
            if (data.endDate) { this._endDate = data.endDate; }
        }
    }

    get support(): string {
        return this._support;
    }

    set support(value: string) {
        this._support = value;
    }

    get genre(): string[] {
        return this._genre;
    }

    set genre(value: string[]) {
        this._genre = value.sort();
    }

    get langue(): string {
        return this._langue;
    }

    set langue(value: string) {
        this._langue = value;
    }

    get artiste(): string {
        return this._artiste;
    }

    set artiste(value: string) {
        this._artiste = value;
    }

    get label(): string {
        return this._label;
    }

    set label(value: string) {
        this._label = value;
    }

    get releaseDate(): string {
        return this._releaseDate;
    }

    set releaseDate(value: string) {
        this._releaseDate = value;
    }

    get endDate(): string {
        return this._endDate;
    }

    set endDate(value: string) {
        this._endDate = value;
    }
}
