import Vue from 'vue';
import Vuex from 'vuex';
import general from './modules/general';
import category from './modules/category';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    general, category,
  },
});
